import { Button } from 'components/shared/button';
import { GithubStars } from 'components/shared/github-stars';
import { Heading } from 'components/shared/heading';
import { HeroFrame } from 'components/shared/hero-frame';
import React from 'react';
import { docs } from 'utils/urls';

import styles from './open-source-hero.module.scss';
import terminalAnimation from './videos/terminal-animation-os.mp4';

import './open-source-hero.scss';

export const Hero = ({ githubStars }) => (
  <section className={styles.wrapper}>
    <div className={`container ${styles.inner}`}>
      <Heading className={styles.title}>k6 Open Source</Heading>
      <p className={styles.description}>An extensible load testing tool built for developer happiness</p>

      <div className={styles.buttonWrapper}>
        {/* fdocs */}
        <Button tag="a" className={styles.btn} cursor href={'https://grafana.com/get/?src=k6io&redirectPath=k6'}>
          Download
        </Button>
      </div>
      <div className={styles.demoLinkWrapper}>
        <GithubStars githubStars={githubStars} />
      </div>
      <div
        className={styles.terminal}
        // eslint-disable-next-line
        dangerouslySetInnerHTML={{
          __html: `<video src="${terminalAnimation}" type="video/mp4" autoPlay muted playsinline loop />`,
        }}
      />
    </div>
    <HeroFrame />
  </section>
);
