import React from 'react';
import GithubLogo from 'svg/github.inline.svg';

import styles from './github-stars.module.scss';

export const GithubStars = ({ githubStars, className }) => (
  <div className={className}>
    <a className={styles.inner} href="https://github.com/grafana/k6" target={'_blank'} rel="noopener noreferrer">
      <div className={styles.title}>
        <GithubLogo />
        <span>GitHub Stars</span>
      </div>
      <div className={styles.count}>
        {typeof githubStars === 'number' ? <span>{`${(githubStars / 1000).toFixed(1)}k`}</span> : <span>19.5k</span>}
      </div>
    </a>
  </div>
);
