import classNames from 'classnames';
import { useLottie } from 'hooks';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

/* Animation datas */
import ApisAndCliAnimation from './animations/apis-and-cli.json';
import AutomatedTestingAnimation from './animations/automated-testing.json';
import HighPerformanceAnimation from './animations/high-performance.json';
import LoadTesting from './animations/load-testing.json';
import MultipleChoicesAnimation from './animations/multiple-choices.json';
import SeamlessScalingAnimation from './animations/seamless-scaling.json';
import TestsInJsAnimation from './animations/tests-in-js.json';
/* styles */
import styles from './paired-block.module.scss';
/* icons */
import Cloud from './svg/cloud.inline.svg';
import Expand from './svg/expand.inline.svg';
import FolderCode from './svg/folder-code.inline.svg';
import Gauge from './svg/gauge.inline.svg';
import Gear from './svg/gear.inline.svg';
import Graph from './svg/graph.inline.svg';

const ANIMATIONS = {
  'apis-and-cli': ApisAndCliAnimation,
  'automated-testing': AutomatedTestingAnimation,
  'tests-in-js': TestsInJsAnimation,
  'multiple-choices': MultipleChoicesAnimation,
  'high-performance': HighPerformanceAnimation,
  'seamless-scaling': SeamlessScalingAnimation,
  'load-testing': LoadTesting,
};

const ICONS = {
  gear: Gear,
  cloud: Cloud,
  code: FolderCode,
  gauge: Gauge,
  graph: Graph,
  expand: Expand,
};

const PairedBlock = (props) => {
  const {
    illustration,
    title,
    description,
    link: { url, icon, name },
    isLast,
    isReversed,
    animationSpeed,
  } = props;

  const [animationPlayRef, isAnimationPlaying] = useInView();

  const [isAnimationReady, setIsAnimationReady] = useState(false);

  const [animation, animationContainerRef] = useLottie(
    {
      animationData: ANIMATIONS[illustration],
      loop: true,
    },
    {
      loaded_images: () => {
        setIsAnimationReady(true);
      },
    }
  );

  useEffect(() => {
    if (animation && isAnimationReady) {
      animation.setSpeed(animationSpeed);
    }
  }, [animation, isAnimationReady, animationSpeed]);

  useEffect(() => {
    if (animation && isAnimationReady) {
      if (isAnimationPlaying) {
        animation.play();
      } else {
        animation.pause();
      }
    }
  }, [animation, isAnimationReady, isAnimationPlaying]);

  const LinkIcon = ICONS[icon];

  let footer = null;

  if (url) {
    footer = (
      <div className={styles.footer}>
        <div className={styles.iconWrapper}>
          <LinkIcon />
        </div>
        <a className={'link'} href={url}>
          {name}
        </a>
      </div>
    );
  }

  let leftSide = (
    <div className={'col-lg-5 col-md-6 d-sm-flex justify-content-sm-center col-12'}>
      <div className={styles.animation} ref={animationContainerRef} aria-hidden />
    </div>
  );

  let rightSide = (
    <div className="col-lg-4 col-md-5">
      <div className={styles.inner}>
        {/* eslint-disable-next-line react/no-danger */}
        <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
        <p className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
        {footer}
      </div>
    </div>
  );

  if (isReversed) {
    [leftSide, rightSide] = [rightSide, leftSide];
  }

  return (
    <section className={classNames(styles.wrapper, isLast ? styles.last : '')} ref={animationPlayRef}>
      <div className="container">
        <div className={`row ${isReversed ? `flex-md-row flex-sm-column-reverse ${styles.rowMobile}` : ''}`}>
          <div className="col-lg-1 d-lg-block d-none" />
          {leftSide}
          <div className="col-md-1 d-md-block d-none" />
          {rightSide}
        </div>
      </div>
    </section>
  );
};

PairedBlock.propTypes = {
  illustration: PropTypes.oneOf(Object.keys(ANIMATIONS)).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.shape({
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.oneOf(Object.keys(ICONS)).isRequired,
  }),
  isReversed: PropTypes.bool,
  animationSpeed: PropTypes.number,
};

PairedBlock.defaultProps = {
  link: {},
  isReversed: false,
  animationSpeed: 1,
};

export default PairedBlock;
