/* eslint-disable max-len */
import { SEO } from 'components/blocks/seo';
import { Hero } from 'components/pages/open-source/hero';
import PairedBlock from 'components/pages/open-source/paired-block';
import { CTA } from 'components/shared/cta';
import { DefaultLayout } from 'layouts/default-layout';
import React from 'react';
import SEO_DATA from 'utils/seo-metadata';

const OSS = ({ pageContext: { githubStars } }) => (
  <DefaultLayout>
    <Hero githubStars={githubStars} />
    <PairedBlock
      illustration={'apis-and-cli'}
      title={'Easy to use APIs and CLI'}
      description={
        'For modern engineering teams. The k6 API and CLI are designed to be intuitive, flexible, and powerful.'
      }
      link={{
        url: 'https://grafana.com/docs/k6/latest/',
        icon: 'gear',
        name: 'Documentation',
      }}
    />
    <PairedBlock
      illustration={'tests-in-js'}
      title={'Write tests in Javascript'}
      description={
        'Build realistic load tests using a familiar scripting language. Reuse modules and Javascript libraries to better build and maintain your test suite.'
      }
      link={{
        url: 'https://grafana.com/docs/k6/latest/javascript-api/',
        icon: 'code',
        name: 'k6 Javascript API',
      }}
      isReversed
    />
    <PairedBlock
      illustration={'automated-testing'}
      title={'Automated testing'}
      description={
        'Integrate performance tests in your CI/CD tool and set up pass/fail criteria to ensure reliability goals. Proactively test your SLOs.'
      }
      link={{
        url: 'https://k6.io/unit-testing-for-performance/',
        icon: 'graph',
        name: 'Like unit-testing for performance',
      }}
    />
    <PairedBlock
      illustration={'high-performance'}
      title={'High-performance tool'}
      description={'The k6 engine is written in Go making it one of the best performing load testing tools available.'}
      link={{
        url: 'https://k6.io/blog/comparing-best-open-source-load-testing-tools',
        icon: 'gauge',
        name: 'Read our performance comparison',
      }}
      animationSpeed={0.45}
      isReversed
    />
    <PairedBlock
      illustration={'multiple-choices'}
      title={'Multiple choices for storage'}
      description={
        '"Big Tent" interoperability is in our DNA! <br/> k6 can output test results to various backends like DataDog, Prometheus, NewRelic, Timescale, etc.'
      }
      link={{
        url: 'https://grafana.com/docs/k6/latest/results-output/',
        icon: 'expand',
        name: 'Result store and visualization',
      }}
    />
    <PairedBlock
      illustration={'load-testing'}
      title={'Beyond load testing'}
      description={
        "Community extensions open new options to test infrastructure's performance: SQL, Browser, Kafka, Kubernetes, Chaos, MQTT, and more."
      }
      link={{
        url: 'https://grafana.com/docs/k6/latest/extensions/',
        icon: 'expand',
        name: 'k6 extensions',
      }}
      isReversed
    />
    <PairedBlock
      illustration={'seamless-scaling'}
      title={'Effortless scaling'}
      description={
        'Build and debug locally, scale to the cloud. Run the same test on your local machine, in a distributed cluster, or k6 Cloud.'
      }
      link={{
        url: 'https://grafana.com/docs/k6/latest/get-started/running-k6/#execution-modes',
        icon: 'cloud',
        name: 'Execution modes',
      }}
      isLast
    />
    <CTA
      title={'Are you ready to try k6?'}
      description={'Free to run on your infrastructure.'}
      buttonText={'Download'}
      buttonRef={'https://grafana.com/get/?src=k6io&redirectPath=k6'}
      themeLight
    />
  </DefaultLayout>
);

export default OSS;

export const Head = () => <SEO {...SEO_DATA['open-source']} />;
